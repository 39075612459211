import Merchant from "./merchant";
import Admin from "./admin";
import Visitor from "./visitor";
import Visitormenu from "./visitor/menu-list-master/src/index";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
    <div>
      <Router>
        <Routes style={{}}>
          <Route path="Merchant/*" element={<Merchant />} />
          <Route path="Admin" element={<Admin />} />
          <Route path="/" element={<Visitor />} />
          <Route path="menu/*" element={<Visitormenu />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
