import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RestaurantMenu from './App';
import ItemDetails from './ItemDetails';

const App = () => {
    console.log('rendering')
  return (
      <Routes>
        <Route path="/" element={<RestaurantMenu />} />
        <Route path="/:id" element={<ItemDetails />} />
      </Routes>
  );
};

export default App;
