import React, { Component } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Directions } from "@mui/icons-material";
import Items from "./items";
class RestaurantMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedCategory: 0,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCategorySelect = (category) => {
    this.setState({ selectedCategory: category });
  };

  render() {
    const { anchorEl, selectedCategory } = this.state;
    const categories = [
      {
        id: 0,
        name: "المقبلات",
        logo: require("./images/logos/plate-sushi-svgrepo-com.svg"),
      },
      {
        id: 1,
        name: "الشاورما",
        logo: require("./images/logos/doner-kebab-svgrepo-com.svg"),
      },
      {
        id: 2,
        name: "البروستد",
        logo: require("./images/logos/turkey-leg-chicken-svgrepo-com.svg"),
      },
      {
        id: 3,
        name: "السندويشات",
        logo: require("./images/logos/wrap-snack-food-svgrepo-com.svg"),
      },
      {
        id: 4,
        name: "البيتزا",
        logo: require("./images/logos/pizza-svgrepo-com.svg"),
      },
      {
        id: 5,
        name: "الوجبات",
        logo: require("./images/logos/meal-svgrepo-com.svg"),
      },
      {
        id: 6,
        name: "المشويات",
        logo: require("./images/logos/barbecue-4-svgrepo-com.svg"),
      },
      {
        id: 7,
        name: "الفطائر",
        logo: require("./images/logos/pie-svgrepo-com.svg"),
      },
      {
        id: 8,
        name: "العصائر",
        logo: require("./images/logos/juice-drink-beverage-svgrepo-com.svg"),
      },
    ];

    const filteredMenuItems = Items.item.filter(
      (item) => item.id === selectedCategory
    );

    return (
      <div style={{ backgroundColor: "white" }}>
        {/* App Bar */}
        <AppBar position="static" style={styles.appBar}>
          <Toolbar sx={{ direction: "rtl" }}>
            <img
              src={require("./images/logo.png")} // Replace with your logo URL
              alt="Restaurant Logo"
              style={styles.logo}
            />
            <Typography variant="h6" style={{ flexGrow: 1, color: "black" }}>
              مطعم الإيوان
            </Typography>
            <IconButton
              edge="end"
              color="black"
              aria-label="menu"
              onClick={this.handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={this.handleMenuClose}>Home</MenuItem>
              <MenuItem onClick={this.handleMenuClose}>About Us</MenuItem>
              <MenuItem onClick={this.handleMenuClose}>Contact</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        {/* Scrollable Categories */}
        <Box style={styles.scrollableCategories}>
          {categories.map((category) => (
            <Box
              key={category.name}
              style={{
                ...styles.categoryCard,
                direction: "ltr",
                backgroundColor:
                  selectedCategory != category.id ? "#f3f4f6" : "#f7979a",
              }}
              onClick={() => this.handleCategorySelect(category.id)}
            >
              <Typography
                sx={{
                  color: selectedCategory != category.id ? "black" : "white",
                  marginRight:1
                }}
                variant="subtitle1"
              >
                {category.name}
              </Typography>
              <img
                src={category.logo}
                alt={`${category.name} Logo`}
                style={styles.categoryLogo}
              />
            </Box>
          ))}
        </Box>

        {/* Menu Content */}
        <Box style={styles.container}>
          {Items.item.map(
            (item) =>
              selectedCategory === item.category && (
                <Link
                  to={`${item.id}`}
                  key={item.id}
                  style={{ textDecoration: "none" }}
                >
                  <Card key={item.id} style={styles.card}>
                    <CardMedia
                      component="img"
                      image={item.image}
                      alt={item.title}
                      style={styles.media}
                    />
                    <CardContent>
                      <Typography style={{ ...styles.title }}>
                        {item.title}
                      </Typography>
                      <Typography sx={{fontSize:10}} color="textSecondary">
                        {item.description}
                      </Typography>
                      <Typography style={{ marginTop: "8px" }}>
                        {`${item.price} ريال` }
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              )
          )}
        </Box>
      </div>
    );
  }
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 64px)", // Adjust height to fit below the AppBar
    overflowY: "auto",
  },
  categoryCard: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    margin: "8px",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    // direction: 'rtl',
    transition: "background-color 0.3s ease",
  },
  categoryLogo: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  scrollableCategories: {
    display: "flex",
    overflowX: "auto",
    padding: "8px 0",
    gap: "8px",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
    direction: "rtl",
  },
  card: {
    display: "flex",
    alignItems: "center",
    padding: "1px",
    margin: "8px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    backgroundColor: "#f3f4f6",
    // flexDirection:"row-reverse",
    direction: "rtl",
  },
  media: {
    height: 120,
    width: 120,
    marginRight: "16px",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    textDecoration: "none",
  },
  appBar: {
    backgroundColor: "white",
  },
  logo: {
    height: "40px",
    marginLeft: "16px",
  },
};
export default RestaurantMenu;
